import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable()
export class EntityService {
  constructor(private http: HttpClient) {}

  private backendUrl = environment.B2CConfigs.BackendURL;

  getEntityByPage = (entity, body) => {
    return this.http.post(`${this.backendUrl}/entity/${entity}/filter`, body);
  }

  getDeviceByPage = body => {
    return this.http.post(`${this.backendUrl}/devicemgmt/filter`, body);
  }

  getDeviceDataById = (id) => {
    return this.http.get(`${this.backendUrl}/devicemgmt/id/${id}`);
  }


  getAllEntityData = entity => {
    return this.http.get(`${this.backendUrl}/entity/${entity}/all`);
  }

  createEntity = (entity, body) => {
    return this.http.post(`${this.backendUrl}/entity/${entity}`, body);
  }

  getEntityDataById = (entity, id) => {
    return this.http.get(`${this.backendUrl}/entity/${entity}/id/${id}`);
  }

  deleteEntityById = (entity, id) => {
    return this.http.delete(`${this.backendUrl}/entity/${entity}/${id}`);
  }

  editEntity = (entity, body) => {
    return this.http.put(`${this.backendUrl}/entity/${entity}`, body);
  }

  getEntityDataByType = (entity, type) => {
    return this.http.get(`${this.backendUrl}/entity/${entity}/all?onlyname=true&devicetype=${type}`);
  }

  getEntityDataByName = (entity, name) => {
    return this.http.get(`${this.backendUrl}/entity/${entity}/name/${name}`);
  }
}
