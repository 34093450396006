import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { PortalData, UsersService } from '@cpq-app/adminstration/users/users.service';
import { AlertService } from '@cpq-app/shared/services/alert.service';
import { MSALService } from '@cpq-app/shared/services/msal.service';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { StatusCodes } from 'http-status-codes';


@Component({
  selector: 'app-second-header',
  templateUrl: './second-header.component.html',
  styleUrls: ['./second-header.component.scss'],
})
export class SecondHeaderComponent implements OnInit {
  opcoDataServiceSubscription: Subscription;
  headerFooterUrls: any = {};
  isOpcoDataLoading = true;
  opcoLogoUrl = 'assets/images/logo-twg.png';
  isPrivacyTerms: boolean;
  userId: string;

  constructor(
    private msalService: MSALService,
    private alertService: AlertService,
    private userService: UsersService,
    private router: Router,
    private toaster: ToastrService
  ) { }
  ngOnInit() {
    const loggedInUserAdInfo = this.msalService.getUser().idToken;
    this.userService.getUser(loggedInUserAdInfo.oid).subscribe((res: PortalData) => {
      if (res.statusCode !== StatusCodes.OK) {
        this.alertService.error(
          `${res.message}`
        );
      } else {
        const userData = res.data;
        if (
          userData === null ||
          typeof userData === typeof undefined ||
          typeof userData.persona === typeof undefined
        ) {
          this.userId = '';
        } else {
          this.userId = userData.Id;
          this.isPrivacyTerms = userData.privacyTerm?.isAccepted;
        }
      }
    });
  }

  goToHome() {
    this.isPrivacyTerms = Boolean(sessionStorage.getItem('privacyTerms'));
    try {
      if (this.isPrivacyTerms) {
        this.router.navigate(['/']);
      }
      else {
        this.router.navigate(['privacy-terms']);
      }
    }
    catch (error) {
      this.toaster.info('issue with privacy-term redirect');
    }
  }
}
