<div class="container">
    <div class="card">
      <h3 class="card-header">Privacy Terms</h3>
      <div class="card-body">
            <div>
                <h4>TWG is committed to protecting and respecting your privacy, and we'll only use your personal information to administer your account and to provide the products and services you required form us. From time to time we would like to contact you about our products and services, as well as other content that may be of interest to you. If you consent to us contacting you for this purpose, please tick below to say how you would like us to contact you.
                </h4>
            <div>
            <div>
                <input type="checkbox" [(ngModel)]="isPrivacyTerms" class="CheckBox"/><span>I have read and agree to the above terms and conditions.</span>
            </div>
            <div class="row">
                <button
                    class="TabMovebutton btn-back-next"
                    [disabled]="!isPrivacyTerms"
                    (click)="accept()"
                >
                    Accept
                </button>
                <button
                    class="TabMovebutton btn-back-next"
                    [disabled]="!isPrivacyTerms"
                    (click)="decline()"
                >
                    Decline
                </button>
            </div>
        </div>
      </div>
    </div>
  </div>
  