import {
  Component,
  EventEmitter, Input,
  OnChanges, OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { GuestConfigService } from '@cpq-app/adminstration/guest-config/guest-config.service';
import { CartService, CpqProposal, CpqQuote, CpqQuoteline } from '@cpq-app/services/cart.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { from, Observable, of, Subscription } from 'rxjs';
import { catchError, mergeMap, switchMap, takeLast } from 'rxjs/operators';
import { HubspotDialogComponent, HubspotForms } from '../hubspot-modal/hubspot-modal.component';
import { SubmitQuoteModelComponent } from '../submit-quote-model/submit-quote-model.component';
import { ThankYouModalComponent } from '../thank-you-modal/thank-you-modal.component';

const FPX_QUANTITY_MAX_LIMIT = 99999;

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss']
})
export class CartComponent implements OnInit, OnChanges, OnDestroy {
  @Input() quoteId: string;
  @Input() opportunityId: string;
  @Output() startNewConfiguration = new EventEmitter();

  quote = new CpqQuote();
  quoteCartCreationDate: Date;
  cartProductCount: number;
  fetchSubscription = new Subscription();
  commonSubscriptions = new Subscription();
  updateSubscription: Subscription;
  products: any[] = [];
  isGuest = true;

  readonly HIDDEN_PRICE = '••••••••••';
  readonly MAX_NUMBER_CONCURRENT_CONFIG_SESSIONS = 4;

  readonly displayedColumns = [
    'graphicName__c',
    'Name',
    'ExtendedDescription',
    'Quantity',
    'Actual_Extended_List_Price__c',
    'Model_Code__c'
  ];

  readonly TOASTR = {
    TEXT: {
      WARNING_TITLE: `Uh oh...`,
      ERROR_TITLE: `We're sorry...`,
      MSG: {
        OPEN_CONFIG_ERROR: `Something went wrong while opening your product; please try again.`,
        UPDATE_ERROR: `Something went wrong while updating your quote; please try again.`,
        INVALID_ENTRY: `That value was invalid; please try again.`,
      }
    },
    VALUE: {
      WARNING_FADE_TIMEOUT: 5000, // in mS
      ERROR_FADE_TIMEOUT: 5000, // in mS
    },
  };

  constructor(
    private cartService: CartService,
    private spinner: NgxSpinnerService,
    public dialog: MatDialog,
    private guestService: GuestConfigService,
    private router: Router,
    private toastr: ToastrService
  ) { }

  ngOnInit() {
    this.isGuest = this.guestService.getGuestUserDetails()?.isGuest || false;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.quoteId) {
      console.log(`%cCart quoteId changed from ${changes.quoteId.previousValue} to ${changes.quoteId.currentValue}`, 'color: blue;');
      this.fetchSubscription.unsubscribe();
      if (this.quoteId) {
        this.fetchSubscription = this.fetchCart(this.quoteId);
        this.cartService.updateQuoteData(this.quoteId).subscribe();
      } else {
        this.clearCart();
      }
    } else {
      console.log('Changes fired but not for quoteId');
    }
  }

  trackByQuoteLineId(index: number, quoteLine: CpqQuoteline) {
    return quoteLine.ProductId;
  }

  ngOnDestroy() {
    this.fetchSubscription.unsubscribe();
  }

  fetchCart(quoteId: string): Subscription {
    let subscription = new Subscription();
    try {
      // Erase the cart values and fetch new ones
      this.spinner.show();
      this.clearCart();

      console.log(`%cSubscribing to quote ${quoteId}`, 'background-color: grey; color: green;');
      subscription = this.cartService.publicationForQuoteWithProducts(quoteId).subscribe(
        quote => {
          console.log(`%cUpdate for quote ${quote.Id} with ${quote?.quoteLines?.length} products`, 'background-color: grey; color: blue;');
          this.quote = quote;
          this.products = quote?.products;
          this.spinner.hide();
          this.refreshProductsPricing();
        },
        err => {
          console.log(err); // FIXME error handling
          this.spinner.hide();
        }
      );

    } catch (err) {
      console.log('Error while fetching Cart Products', err); // FIXME error handling
    }

    return subscription;
  }

  refreshProductsPricing() {
    const currentDate = new Date();
    const expirationDate = new Date(this.quote.ExpirationDate);
    if (expirationDate <= currentDate && this.quote.quoteLines.length > 0) {
      this.toastr.info('The pricing on your quote has expired and will now be updated', 'Information', {
        closeButton: true,
        positionClass: 'toast-top-center',
        timeOut: 15000,
      });

      if (this.updateSubscription) {
        this.updateSubscription.unsubscribe();
      }

      // Alter the local copy of the expiration date to prevent an unecessary repeat
      currentDate.setDate(currentDate.getDate() + 1);
      this.quote.ExpirationDate = currentDate;

      this.updateSubscription = from(this.quote.quoteLines).pipe(
        mergeMap<CpqQuoteline, Observable<boolean>>(
          quoteline => this.cartService.resaveProduct(quoteline.ProductId),
          this.MAX_NUMBER_CONCURRENT_CONFIG_SESSIONS),
        takeLast(1),
        mergeMap(x => this.cartService.updateQuoteData(this.quoteId))
      ).subscribe(
        // Do nothing on success
      );
    }
  }

  private clearCart() {
    if (this.quote?.quoteLines?.length) {
      this.quote.quoteLines = [];
    }
  }

  public calculateNetTotal() {
    return this.quote?.quoteLines?.reduce(
      (price, quoteLine) => price + quoteLine?.TotalSellingPrice,
      0
    );
  }

  quoteProposalUrl(proposal: CpqProposal) {
    return this.cartService.getProposalUrl(proposal?.Id);
  }

  navigateToPreSelects() {
    this.startNewConfiguration.emit();
  }

  deleteProduct(productId: string) {
    if (this.quote?.quoteLines) {
      this.quote.quoteLines = this.quote.quoteLines.filter(quoteline => quoteline.Id !== productId);

      this.recalculateQuoteTotal();
    }

    const deleteSubscription = this.cartService
      .deleteProduct(productId).pipe(
        switchMap(x => this.cartService.updateQuoteData(this.quoteId)),
      )
      .subscribe(
        data => {
        },
        error => {
          console.warn(`An error occurred while deleting item ${productId}`);
          this.cartService.updateQuoteData(this.quoteId);
        }
      );

    // this.commonSubscriptions.add(deleteSubscription);
  }

  recalculateQuoteTotal() {
    if (this.quote?.Selling_Price__c !== undefined) {
      const sum = (total, ql) => total + (ql.TotalSellingPrice || 0);
      this.quote.Selling_Price__c = this.quote?.quoteLines.reduce(sum, 0);
    }
  }

  openSubmitQuoteDialog() {
    const submitQuoteDialogConfig = this.submitQuoteDialogConfig();
    const submitQuoteDialogRef = this.dialog.open(SubmitQuoteModelComponent, submitQuoteDialogConfig);

    const thankYouConfig = this.thankYouModalConfig();
    submitQuoteDialogRef.afterClosed().pipe(
      switchMap(x => {
        if (x.success) {
          return this.dialog.open(ThankYouModalComponent, thankYouConfig).afterClosed();
        }
        return of(x);
      })
    ).subscribe(x => {
      if (!this.isGuest && x.success) {
        this.clearCart();
      }
    }, err => {

    });

  }

  private submitQuoteDialogConfig() {
    const submitQuoteDialogConfig = new MatDialogConfig();
    submitQuoteDialogConfig.disableClose = true;
    submitQuoteDialogConfig.autoFocus = true;
    submitQuoteDialogConfig.width = '600px';
    submitQuoteDialogConfig.data = {
      quoteName: this.quote?.Name,
      quoteNote: this.quote?.Note,
      quoteId: this.quoteId,
      opportunityId: this.opportunityId,
    };
    return submitQuoteDialogConfig;
  }

  private thankYouModalConfig() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '600px';
    dialogConfig.data = {
    };
    return dialogConfig;
  }

  updateQuantity(quoteline: CpqQuoteline) {
    const qty = quoteline.Quantity;
    if (Number.isNaN(qty)) {
      this.toastr.error(this.TOASTR.TEXT.MSG.INVALID_ENTRY, this.TOASTR.TEXT.ERROR_TITLE, {
        timeOut: this.TOASTR.VALUE.ERROR_FADE_TIMEOUT,
      });
      this.cartService.updateQuoteData(this.quoteId);
      return;
    }
    if (this.isQuantityOutOfRange(qty)) {
      return;
    }

    if (qty === 0) {
      this.deleteProduct(quoteline.Id);
    } else {

      if (quoteline.TotalSellingPrice != undefined) {
        quoteline.TotalSellingPrice = quoteline.UnitSellingPrice * quoteline.Quantity;
        this.recalculateQuoteTotal();
      }

      const updateSubscription = this.cartService.updateObjectId(quoteline.Id, {
        Quantity: qty,
      }).pipe(
        catchError(err => {
          this.toastr.warning(this.TOASTR.TEXT.MSG.UPDATE_ERROR, this.TOASTR.TEXT.WARNING_TITLE, {
            timeOut: this.TOASTR.VALUE.WARNING_FADE_TIMEOUT,
          });
          return of(true);
        }),
        mergeMap(x => this.cartService.updateQuoteData(this.quoteId))
      ).subscribe();
    }
  }

  isSubmitDisabled(): boolean {
    const allValid = this.quote?.quoteLines?.reduce(
      (valid: boolean, ql) => valid && this.isQuoteLineValid(ql),
      true
    );
    return !allValid;
  }

  isQuoteLineValid(ql: CpqQuoteline): boolean {
    return (ql.Quantity && !this.isQuantityOutOfRange(ql.Quantity));
  }

  getProductImage(productId: string) {
    const selectedProduct = this.products.filter(item => item.Id === productId);
    return selectedProduct[0].graphicName__c;
  }
  getProductModelCode(productId: string) {
    const selectedProduct = this.products.filter(item => item.Id === productId);
    return selectedProduct[0].Model_Code__c;
  }
  getProductProposalNotes(productId: string) {
    const selectedProduct = this.products.filter(item => item.Id === productId);
    return selectedProduct[0].Proposal_Notes__c !== undefined ? selectedProduct[0].Proposal_Notes__c : '';
  }
  getProductActualExtendedListPrice(productId: string) {
    const selectedProduct = this.products.filter(item => item.Id === productId);
    return selectedProduct[0].Actual_Extended_List_Price__c;
  }

  getUnitPrice(ProductId: string) {
    const selectedProduct = this.products.filter(item => item.Id === ProductId);
    return selectedProduct[0]?.Actual_Unit_List_Price__c;
  }

  getListPrice(ProductId: string) {
    const selectedProduct = this.products.filter(item => item.Id === ProductId);
    return selectedProduct[0]?.TotalList;
  }
  /**
   * Opens a material modal to show Hubspot forms
   */
  openHubspotRequest() {
    this.dialog.open(HubspotDialogComponent, {
      data: {
        formName: HubspotForms.RequestAccess
      }
    });
  }

  onModelCodeClick(productId: string) {
    this.spinner.show();
    const subscription$ = this.cartService
      .reopenConfiguration(productId)
      .subscribe(configData => {
        this.router.navigate(
          [
            '/products/configuration',
            this.opportunityId,
            this.quoteId,
            configData.configId
          ],
          { queryParams: { update: 'product', productID: productId } }
        );
      }, err => {
        // Navigation event failed
        this.toastr.warning(this.TOASTR.TEXT.MSG.OPEN_CONFIG_ERROR, this.TOASTR.TEXT.WARNING_TITLE, {
          timeOut: this.TOASTR.VALUE.WARNING_FADE_TIMEOUT,
        });
        this.cartService.updateQuoteData(this.quoteId);
        this.spinner.hide();
      });
  }

  isQuantityOutOfRange(quantity: number): boolean {
    return (quantity > FPX_QUANTITY_MAX_LIMIT) ? true : false;
  }
}
