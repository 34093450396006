import { Component} from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'change-password-success',
  templateUrl: './changePasswordSuccess.component.html',
  styleUrls: ['./changePasswordSuccess.component.scss']
})
export class ChangePasswordSuccessComponent {
  constructor(
    private router:Router
  ) { 
  }

  navigateToDashboard(){
    this.router.navigate(['/']);
  }
}
