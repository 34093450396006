import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject, BehaviorSubject } from 'rxjs';
import { environment } from '@cpq-environments/environment';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class HeaderService {
  private backendUrl = environment.B2CConfigs.BackendURL;
  constructor(private http: HttpClient) {}
  private subject = new Subject<Object>();
  userName = new BehaviorSubject('');

  getHeader(loggedInUserOid) {
    return this.http.get(
      `${this.backendUrl}/authorization/getConfigurations/MainMenu/${loggedInUserOid}`
    );
  }

  updateHeader(headerData) {
    this.subject.next(headerData);
  }

  getUpdatedHeader() {
    return this.subject.asObservable();
  }

  setUsername(name) {
    this.userName.next(name);
    this.getUserName();
  }

  getUserName() {
    return this.userName.asObservable();
  }
}
