import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable()
export class StaticjsonfileService {
  constructor(private http: HttpClient) {}

  private backendUrl = environment.B2CConfigs.BackendURL;

  getCountries() {
    return this.http.get(`${this.backendUrl}/static/data/Country/all`);
  }

  getStatesByCountryId(countryId: string) {
    return this.http.get(`${this.backendUrl}/static/data/State/${countryId}`);
  }

  getCitiesByStateId(stateId: string) {
    return this.http.get(`${this.backendUrl}/static/data/City/${stateId}`);
  }
}
