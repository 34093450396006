import { DatePipe } from '@angular/common';
import { AfterViewInit, Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { GuestConfigService } from '@cpq-app/adminstration/guest-config/guest-config.service';
import { CartService, CpqQuote, CpqProposal } from '@cpq-app/services/cart.service';
import { getTime } from 'ngx-bootstrap/chronos/utils/date-getters';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { HubspotDialogComponent, HubspotForms } from '../hubspot-modal/hubspot-modal.component';

@Component({
  selector: 'app-quote',
  templateUrl: './quote.component.html',
  styleUrls: ['./quote.component.scss'],
  providers: [DatePipe]
})
export class QuoteComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() quoteId: string;
  quotes = new MatTableDataSource<any>();
  isQuotesLoading = true;
  quoteRange = '1';
  noQuotesItems = false;
  quoteSubscription: Subscription;
  isGuest: boolean;
  
  @Output() startNewConfiguration = new EventEmitter();

  readonly HIDDEN_PRICE = '••••••••••';
  private paginator: MatPaginator;
  private sort: MatSort;
  minDate:Date;
  maxDate:Date;
  minDateInitial:Date;
  dateSelectionError=false;
  dateSelectionErrorText:string;
  startDate:any;
  endDate:any;
  constructor(
    private datePipe: DatePipe,
    private cartService: CartService,
    public dialog: MatDialog,
    private guestService: GuestConfigService,
    private spinner: NgxSpinnerService,
  ) {this.minDate = new Date();
    this.maxDate = new Date();
    this.minDateInitial = new Date();
    this.minDate.setMonth(this.minDate.getMonth() - 6);
    this.minDateInitial.setMonth(this.maxDate.getMonth()-1)
    this.daterangepickerModel = [this.minDateInitial, this.maxDate];
  }
    daterangepickerModel:any[]
  displayedColumnsForQuote: string[] = [
    'LastModifiedDate',
    'FormattedId',
    'Name',
    'Note',
    'Selling_Price__c',
    'ExpirationDate'
  ];

  @ViewChild(MatSort) set matSort(mSort: MatSort) {
    this.sort = mSort;
    this.setDataSourceAttributes();
  }

  @ViewChild(MatPaginator) set matPaginator(mPaginator: MatPaginator) {
    this.paginator = mPaginator;
    this.setDataSourceAttributes();
  }

  setDataSourceAttributes() {
    this.quotes.paginator = this.paginator;
    this.quotes.sort = this.sort;
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.quotes.filter = filterValue;
  }

  ngAfterViewInit() {
    this.setDataSourceAttributes();
  }

  ngOnInit() {
    this.isGuest = this.guestService.getGuestUserDetails().isGuest || false;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.quoteId) {
      console.log('Refetching quotes');
    }
    this.fetchQuotesWithinRange(this.startDate, this.endDate);
  }

  fetchQuotesWithinRange(startDate,endDate) {
    this.isQuotesLoading = true;
    if (this.quoteSubscription) {
      this.quoteSubscription.unsubscribe();
    }
    this.quotes.data = [];
    this.spinner.show();
    this.quoteSubscription = this.cartService
      .fetchQuotes(startDate, endDate)
      .pipe(
        map<CpqQuote[], CpqQuote[]>(qs => qs.filter(q => CpqQuote.checkIsClosed(q) && q.Selling_Price__c))
      )
      .subscribe(
        quotes => {
          this.spinner.hide();
          this.quotes.data = this.quotes.data.concat(quotes);
          this.noQuotesItems = this.quotes.data.length === 0;
          console.log(`Adding ${quotes.length} quotes to the table`, quotes);
        },
        err => {
          this.spinner.hide();
          this.isQuotesLoading = false;
          this.ngAfterViewInit();
        },
        () => {
          this.isQuotesLoading = false;
          this.ngAfterViewInit();
        }
      );
  }
  dateValidation(selectedDate){
    let validationObject={isDateValid:false,validationMessage:''}
    if(selectedDate == null){
      validationObject.isDateValid =true
      validationObject.validationMessage="Please select valid dates"
    }else if(selectedDate ==  ''||(selectedDate.length < 2) || (selectedDate[0] == null|| selectedDate[0] == '') || (selectedDate[1] == null||selectedDate[1] ==  '')){
      validationObject.isDateValid =true
      validationObject.validationMessage="Please select valid dates"
    }else if((new Date(this.dateFormat(selectedDate[0])).getTime()>new Date(this.dateFormat(selectedDate[1])).getTime())){
      validationObject.isDateValid =true
      validationObject.validationMessage="Please check from-date and to-Date selection"
    }else if (new Date(this.dateFormat(selectedDate[1])).getTime()>(new Date(this.dateFormat (this.maxDate)).getTime())){
      validationObject.isDateValid =true
      validationObject.validationMessage="You can't get future Quotes"
    }else if (new Date(this.dateFormat(selectedDate[0])).getTime()< (new Date(this.dateFormat(this.minDate)).getTime())){ 
      validationObject.isDateValid =true
      validationObject.validationMessage="You can get only past 6 months Quotes"
    }
    return validationObject;
  }
  dateFormat(date){
    return this.datePipe.transform(new Date(date), 'yyyy-MM-dd');
  }
  onQuoteRangeChange(item) {
    let dateValidationObject = this.dateValidation(item)
   if(dateValidationObject.isDateValid){
    this.dateSelectionError = dateValidationObject.isDateValid;
    this.dateSelectionErrorText = dateValidationObject.validationMessage;
    
   }else{
      this.dateSelectionError = false;
    this.startDate = this.dateFormat(item[0]);
    this.endDate = this.dateFormat(item[1]);
      this.fetchQuotesWithinRange(this.startDate,this.endDate);
    }
    
  }
  
  quoteProposalUrl(proposal: CpqProposal) {
    return this.cartService.getProposalUrl(proposal?.Id);
  }

  /**
   * Opens a material modal to show Hubspot forms
   */
  openHubspotRequest() {
    this.dialog.open(HubspotDialogComponent, {
      data: {
        formName: HubspotForms.RequestAccess
      }
    });
  }

  navigateToPreSelects() {
    this.startNewConfiguration.emit();
  }
}
