import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

const EXCEL_TYPE =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';
import { ImportExportConfig } from '../../tile/exportimport-config';

@Injectable()
export class ExcelService {
  constructor() {}

  public exportAsExcelFile(json: any[], excelFileName: string): void {
    // console.log("Excel Service called",json);

    const fileName = excelFileName;
    json.forEach(function(element, i) {
      delete element.imageUrl;
      delete element._id;
      switch (excelFileName) {
        case 'User Groups':
          ImportExportConfig['UserGroup'].forEach(
            x => (element[x] = JSON.stringify(element[x]))
          );
          break;
        case 'Users':
          ImportExportConfig['User'].forEach(
            x => (element[x] = JSON.stringify(element[x]))
          );
          break;
        case 'Organizations':
          ImportExportConfig['Organization'].forEach(
            x => (element[x] = JSON.stringify(element[x]))
          );
          break;
        case 'Device Management':
          ImportExportConfig['Device Management'].forEach(
            x => (element[x] = JSON.stringify(element[x]))
          );
          break;
      }
    });

    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);

    const Sheet = {};
    Sheet[excelFileName] = worksheet;
    const workbook: XLSX.WorkBook = {
      Sheets: Sheet,
      SheetNames: [excelFileName],
      Props: { Title: excelFileName }
    };
    //  const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
    XLSX.writeFile(
      workbook,
      fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION
    );
    //  this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  public importAsExcelFile(readerResult) {
    try {
      let entityName = '';
      const arrbuffer: ArrayBuffer = <ArrayBuffer>readerResult;
      const data = new Uint8Array(arrbuffer);
      const arr = new Array();
      for (let i = 0; i !== data.length; ++i) {
        arr[i] = String.fromCharCode(data[i]);
      }
      const bstr = arr.join('');
      const workbook = XLSX.read(bstr, { type: 'binary' });
      entityName = workbook.Props.Title;
      const wsname = workbook.SheetNames[0];
      const ws = workbook.Sheets[wsname];
      const dataJson = XLSX.utils.sheet_to_json(ws, { header: 1 });
      return [entityName, dataJson];
    } catch {
      return null;
    }
  }

  /*   private saveAsExcelFile(buffer: any, fileName: string): void {
      const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE
      });
      FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
    } */
}
