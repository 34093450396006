import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, Router  } from '@angular/router';
import { CartService } from 'src/app/services/cart.service';
import { withLatestFrom } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AppPathResolve implements Resolve<any> {
  constructor(private cart: CartService, private router: Router) { }
  resolve(route: ActivatedRouteSnapshot) {
    const opportunity = route.paramMap.get('opportunity');
    const quote = route.paramMap.get('quote');
    const config = route.paramMap.get('config');
     if (opportunity === null || quote === null ) {
        this.cart.createOpportunityId().subscribe((opp) => {
          this.cart.createQuoteId(opp).subscribe((quo) => {
            if ( route.url[3].path !== 'cart') {
              this.router.navigate(['/twg', opp.record[0].Id, quo, route.url[3].path, config]);
                // FIXME : /twg
            } else {
              this.router.navigate(['/twg', opp.record[0].Id, quo, route.url[3].path]);
                // FIXME : /twg
            }
          });
        });
      } else {
          this.cart.validateOpportunityId(opportunity).subscribe((newopp) => {
            this.cart.validateQuoteId(newopp).subscribe((quo) => {
                      if (opportunity === newopp && quote === quo) { // it is a valid param.
                        return true;
                      } else {  // form a valid URL and navigate()
                            if (route.url[3].path !== 'cart') {
                              this.router.navigate(['/twg', newopp.records[0].Id, quo, route.url[3].path, config]);
                              // FIXME : /twg
                            } else {
                              this.router.navigate(['/twg', newopp.records[0].Id, quo, route.url[3].path]);
                                // FIXME : /twg
                            }
                          return false;
                      }
                  });
              });
        }
  }
}
