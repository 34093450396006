<form [formGroup]="submitQuoteForm">
    <div>
        <div fxLayout="row" fxLayoutAlign="end end">
            <button mat-button class="close-icon" (click)="close()">
                <mat-icon>close</mat-icon>
            </button>
        </div>
    </div>
    <h1 fxLayout="row" class="cpq-page-header textcapitalize">Submit Quote</h1>
    <div fxLayout="column" fxLayout.lt-md="column" mat-dialog-content class="submit-quote-container">
        <ngx-spinner [fullScreen]="false" type="ball-scale-ripple" size="medium" [name]="SUBMIT_SPINNER">
            <p class="loading">Please wait...</p>
        </ngx-spinner>
        <div *ngIf="availableAccounts.length >= 1">
            <mat-form-field class="margin-top_20 select-w" required>
                <mat-label class="float-label">Account name</mat-label>
                <mat-select [compareWith]="compareObjects" [(ngModel)]="selectedAccount"
                    (selectionChange)="onAssociatedAccountChange()" formControlName="accountSelection">
                    <mat-option *ngFor="let account of availableAccounts" [value]=account class="account-text">
                        {{account.Name}} {{account.AccountNumber}}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="validateRequiredField('accountSelection')">Please select an account.</mat-error>
            </mat-form-field>
        </div>
        <mat-form-field floatLabel="always">
            <mat-label class="float-label">Name the Quote</mat-label>
            <input matInput type="text" formControlName="quoteName" [(ngModel)]="data.quoteName" cdkFocusInitial
                required>
            <mat-error *ngIf="validateRequiredField('quoteName')">A name is required.</mat-error>
            <mat-error *ngIf="validateMaxLength('quoteName')">The name is too long.</mat-error>
        </mat-form-field>
        <mat-form-field floatLabel="always">
            <mat-label class="float-label">Notes</mat-label>
            <textarea matInput type="text" class="notes" formControlName="quoteNote" [(ngModel)]="data.quoteNote"
                rows="7"></textarea>
            <mat-error *ngIf="validateMaxLength('quoteNote')">Please make your note shorter.</mat-error>
        </mat-form-field>
        <div class="disclaimer">TWG is committed to protecting and respecting your privacy, and we’ll only
            use your
            personal information to administer your account and to provide the products and services
            you requested from us. From time to time, we would like to contact
            you about our products and services, as well as other content that may be of interest to you.
            If you consent to us contacting you for this purpose, please submit this
            request by clicking the button below.</div>
        <button mat-flat-button color="accent" class="textcapitalize cpq-button-text"
            [disabled]="!submitQuoteForm.valid" (click)="submitQuote()">SUBMIT</button>
    </div>
</form>