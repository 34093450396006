import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MSALService } from './msal.service';
import { AppConstant } from '../constants/app-constants';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PermissionsService {
  /**
   *
   */
  constructor(private http: HttpClient, private msalService: MSALService) {}

  getPermissions(resourceId) {
    const userOid = this.msalService.getUserOid();
    return this.http.get(
      `${environment.B2CConfigs.BackendURL}/authorization/getResourceActions/${userOid}/${resourceId}`
    );
  }

  getTemplatePermissions(permissions) {
    const permissionArray = {
      CREATE: false,
      READ: false,
      UPDATE: false,
      DELETE: false,
    };
    if (permissions !== null) {
      permissionArray['CREATE'] = permissions.some(
        (permission) => permission === AppConstant.actions.Create
      );
      permissionArray['READ'] = permissions.some(
        (permission) => permission === AppConstant.actions.Read
      );
      permissionArray['UPDATE'] = permissions.some(
        (permission) => permission === AppConstant.actions.Update
      );
      permissionArray['DELETE'] = permissions.some(
        (permission) => permission === AppConstant.actions.Delete
      );
    }
    return permissionArray;
  }
}
