import { InjectionToken } from '@angular/core';
export const environment = {
  production: true,
  version: "0.0.0.0",
  B2CConfigs: {
    BackendURL: 'https://cpqportal-dev.dovertech.co.in/api',
    BlobStorageURL: 'http://entitystorage.blob.core.windows.net',
    DefaultBlobId: 'private',
    ImageBackendUrl: 'https://cpqportal-dev.dovertech.co.in/api',
    b2cScopes:'https://cxportal.onmicrosoft.com/551ce405-ec1f-4843-910b-0c2cbf2f7766/user.read',
    clientId: '551ce405-ec1f-4843-910b-0c2cbf2f7766',
    isPolicy: true,
    redirectUrl: 'https://cpqportal-dev.dovertech.co.in',
    superAdminOid: '83c29ea1-cb34-4203-9e87-b6e606af6cf0',
    tenant: 'cxportal.onmicrosoft.com',
    authURL: "https://cxportal.b2clogin.com/tfp",
    signinPolicy: {
      TWG: 'b2c_1a_cxportal_signin_policy_twg',
      VWS: 'B2C_1A_signup_signin-CPQ-VWS',
    },
    resetPasswordPolicy: {
      TWG: 'B2C_1A_PasswordResetTWG',
      VWS: 'B2C_1A_PasswordReset-CPQ-VWS'
    },
    changePasswordPolicy: {
      TWG: 'B2C_1A_PasswordChangeTWG',
      VWS: 'B2C_1A_ProfileEditPasswordChange-CPQ-VWS'
    },
    b2cLoginURL: 'https://cxportal.b2clogin.com',
    passwordChangeSuccessUrl: 'https://cpqportal-dev.dovertech.co.in/changePasswordSuccess',
    cxPortal: {
      baseUrl:'https://cxportal-dev-twg.dovertech.co.in'
    }
  },
  fpx: {

  },
  cds: {
    endpoint: 'qa',
  },
  dataHubUrl: 'https://apidev.dovercorporation.com/oneview/customer?type=abstract&opco=twg&subscription-key=6f73e387053b4a5182033d4ff452bb4b&_boomi_debug=true'
};