 export const emailPattern = "^[a-zA-Z0-9]{1,64}(?:[-._][a-zA-Z0-9]{1,64}){0,4}@[a-zA-Z0-9]{1,125}(?:[-._][a-zA-Z0-9]{1,64}){0,4}[.]{1}[a-zA-Z]{2,63}(?:[.][a-zA-Z0-9]{1,64}){0,4}$";
 export const mobnumPattern = '^[0-9]{1,14}$';
 export const usernamePattern = '^[a-zA-Z0-9]+([_][a-zA-Z0-9]+)*[a-zA-Z0-9]$';
 export const namePattern = '^([A-Za-z0-9]{1,64}([\' -@]))*[A-Za-z]+?$';
 export const orgNamePattern = "^(?=[a-zA-Z_s].*)[a-zA-Z0-9_ d!-]+$";
 export const firstnamePattern = '^([A-Za-z0-9]{1,64}([\' -@]))*[A-Za-z]+?$';
 export const zipcodePattern = '^[a-zA-Z0-9_-\\s]{0,20}$';
 export const titlePattern = '^[A-Za-z]+$';
 export const addressPattern = '^[a-zA-Z0-9._ ,\'-]*$';
 export const cityNamePattern = '^[a-zA-Z ]+$';
 export const uniqueCodePattern = "^(?=[a-zA-Z_s].*)[a-zA-Z0-9_ d!-]+$";
 export const cityPattern = "^[a-zA-Z ]+$";