import { Component, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CartService, CpqOpportunity, CpqQuote } from '@cpq-app/services/cart.service';
import { LoginService } from '@cpq-app/services/login.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Observable, of, Subscription } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { IframeDialogComponent } from '../iframe-modal/iframe-modal.component';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss']
})
export class LandingPageComponent implements OnInit, OnChanges, OnDestroy {
  showChildren = false;
  private commonSubscriptions = new Subscription();
  private quoteSubscription = new Subscription();
  searchString = '';
  opportunityId: string;
  quoteID: string;
  LOGIN_SPINNER = 'login';

  constructor(
    private cartService: CartService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private toastr: ToastrService,
    public dialog: MatDialog,
    private loginService: LoginService
  ) { }

  ngOnInit(): void {
    console.log(`%cInit Landing Page`, 'color: green;');
    this.spinner.hide().then(() => {
      console.log('Show spinner');
      this.spinner.show(this.LOGIN_SPINNER);
    });

    const loggedIn$ = this.loginService.loginUser();
    const subscription = loggedIn$.subscribe(
      resp => this.fetchLatestOpportunityID()
    );
    this.commonSubscriptions.add(subscription);
  }

  ngOnChanges(changes?: SimpleChanges): void {

  }

  createOpportunity() {
    const subscription$ = this.cartService.createOpportunityId().subscribe(
      results => {
        this.opportunityId = results[0].Id;
        this.cartService.store('opportunityId', this.opportunityId);
        this.createQuote();
      },
      err => {
        // FIXME: we need to handle this
        this.toastr.error(
          'There was a fatal error while creating opportunity id'
        );
        this.spinner.hide();
      }
    );
    this.commonSubscriptions.add(subscription$);
  }

  createQuote() {
    this.quoteSubscription.unsubscribe();
    const subscription$ = this.cartService
      .createQuoteId(this.opportunityId)
      .subscribe(
        quo => {
          this.quoteID = quo[0].Id;
          this.cartService.store('quoteId', this.quoteID);
          console.log(`Created Quote ${this.quoteID}`);
          this.quoteSubscription = this.cartService.publicationForQuoteWithProducts(this.quoteID).subscribe(quote => {
            if (CpqQuote.checkIsClosed(quote)) {
              this.quoteSubscription.unsubscribe();
              this.quoteID = null;
              console.log(`%cNotified quote is now closed`, 'color: green;');
              this.fetchLatestOpportunityID();
            }
          });
          this.showChildComponents();
        },
        err => {
          // FIXME: we need to handle this
          this.toastr.error('There was a fatal error while creating quote id');
          this.spinner.hide();
        }
      );
    this.commonSubscriptions.add(subscription$);
  }



  fetchLatestOpportunityID() {
    this.cartService.getMostRecentOpportunity().subscribe(id => {
      console.log(`Found Opp ${id}`);
      if (!id) {
        this.createOpportunity();
      } else {
        this.opportunityId = id;
        this.fetchAssociatedQuote(id);
      }
    });
  }

  initializeOppAndQuote() {
    /*
    Get the most recent opp and it's quote
    if there is no opp, or the opp's quote is closed
    kickoff creation of both
    otherwise if there is no quote
    kickoff quote creation

    subscribe to each object and trigger initialize if they become invalid

    */
    // this.cartService.getMostRecentOpportunity().subscribe(id => {
    //   if (!id) {
    //     this.createOpportunity();
    //   } else {
    //     this.opportunityId = id;
    //     this.fetchAssociatedQuote(id);
    //   }
    // });

    // this.cartService.getMostRecentOpportunityAndQuote().subscribe(opp => {
    //   if (!opp?.id || (opp?.id && opp?.quotes[0]?.isClosed)) {
    //     this.needNewOppAndQuote().subscribe();
    //   } else if (!opp?.quotes[0]) {
    //     this.needQuote();
    //   } else {

    //   }


    // }, err => {
    //   debugger;
    //   console.log(err);
    // });

    this.cartService.getMostRecentOpportunityAndQuote().pipe(
      switchMap<CpqOpportunity, Observable<CpqOpportunity>>(opp => {
        if (!opp?.id || (opp?.id && opp?.quotes[0]?.isClosed)) {
          return this.cartService.createOpportunity();
        }
        return of(opp);
      }),
      switchMap<CpqOpportunity, Observable<CpqOpportunity>>(opp => {
        if (!opp.quotes) {
          return this.cartService.createQuote(opp.id).pipe(
            map<CpqQuote, CpqOpportunity>(quote => {
              opp.quotes = [quote];
              return opp;
            }),
          );
        }
        return of(opp);
      }),
    ).subscribe(opp => {

    }, err => {

    });


  }

  fetchAssociatedQuote(oppId: string) {
    this.cartService
      .getMostRecentQuoteIdOnOpp(oppId)
      .subscribe(quotes => {
        if (quotes.length > 0) {
          const quote = quotes[0];
          // If the quote has been submitted or processed, create a new opportunity
          if (CpqQuote.checkIsClosed(quote)) {
            console.log(`On Opp ${oppId} Quote ${quote.Id} is closed, making new opp`);
            this.createOpportunity();

          } else {
            this.quoteID = quote.Id;
            this.showChildComponents();
          }

        } else {
          // If the opportunity doesn't have a quote, create it
          console.log(`On Opp ${oppId} no quotes`);
          this.createQuote();
        }
      });
  }

  showChildComponents() {
    this.quoteSubscription = this.cartService.publicationForQuoteWithProducts(this.quoteID).subscribe(quote => {
      if (CpqQuote.checkIsClosed(quote)) {
        this.quoteSubscription.unsubscribe();
        console.log(`%cChanges to Landing Page`, 'color: green;');
        this.fetchLatestOpportunityID();
      }
    });
    this.showChildren = true;
    this.cartService.remove('GUID');
    this.cartService.remove('product');
    this.spinner.hide(this.LOGIN_SPINNER);
  }

  ngOnDestroy() {
    this.commonSubscriptions.unsubscribe();
  }

  startNewConfiguration() {
    this.spinner.show();

    const openSubscription = this.cartService
      .openConfigurationDefaultDataset(this.opportunityId, this.quoteID)
      .subscribe(
        configData => {
          this.router.navigate([
            // FIXME: Handle promise's failure to navigate
            '/products/selection',
            this.opportunityId,
            this.quoteID,
            configData.configId
          ]);
        },
        err => {
          this.spinner.hide();
          // FIXME: We need to better handle this
          this.toastr.error(
            'There was a fatal error while starting a configuration'
          );
        }
      );

    // this.commonSubscriptions.add(openSubscription);
  }

  startProductSearchConfiguration() {
    const rex = /^[a-zA-Z0-9_ ]*$ |([\w ]+)/;
    if (!rex.test(this.searchString.trim())) {
      this.toastr.error('Please enter valid search text');
      return;
    }
    this.spinner.show();

    const subscription$ = this.cartService
      .openConfigurationDefaultDataset(this.opportunityId, this.quoteID)
      .subscribe(configData => {
        this.router.navigate(
          [
            '/products/results',
            this.opportunityId,
            this.quoteID,
            configData.configId
          ],
          { queryParams: { search: this.searchString } }
        );
      }, err => {
        // FIXME: we need to handle this
        this.toastr.error(
          'There was a fatal error while starting a configuration'
        );
        this.spinner.hide();
      });

    // this.commonSubscriptions.add(subscription$);
  }

  /**
   * Opens a material modal
   * @param destination as `string`
   */
  openModal(destination?: string) {
    let url: string;
    switch (destination) {
      case 'video':
        url = 'https://www.dovertwg.com/cpq-how-to/';
        break;

      case 'faq':
      default:
        url = 'https://www.dovertwg.com/cpq-faq/';
        break;
    }

    this.dialog.open(IframeDialogComponent, {
      data: {
        url
      },
      width: '80%',
      height: '80%',
    });
  }
}
