import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AppConfig {
  config;

  constructor(private http: HttpClient) {}

  public getConfig(key: any) {
    return this.config[key];
  }

  public loadConfigurations() {
    return new Promise((resolve, reject) => {
      this.http.get('../../config.json').subscribe(
        res => {
          this.config = res;
          resolve(true);
        });
    });
  }
}
