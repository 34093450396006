import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@cpq-environments/environment';
import { Observable } from 'rxjs';

@Injectable(
  { providedIn: 'root' }
)
export class SalesforceProxyService {
  constructor(
    private http: HttpClient) { }

  private backendUrl = environment.B2CConfigs.BackendURL;

  createOpportunity(quoteId: string, quoteName: string, accountNumber?: string): Observable<SalesforceResult> {
    return this.http.post(`${this.backendUrl}/salesforce/opportunity`, { quoteId, quoteName, accountNumber });
  }

}

export interface SalesforceResult {
  errors?: Array<any>;
  id?: string;
  externalId?: string;
}
