import { AfterViewChecked, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CartService, CpqAccount } from '@cpq-app/services/cart.service';
import { SalesforceProxyService } from '@cpq-app/services/salesforce.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { forkJoin, Observable, Subscription, of, iif } from 'rxjs';
import { delay, switchMap, map } from 'rxjs/operators';
import { GuestConfigService } from '@cpq-app/adminstration/guest-config/guest-config.service';
const FPX_CREATION_DELAY = 2000; // mS
const CLOSING_EXTENSION_DAYS = 30;

@Component({
  selector: 'app-submit-quote-model',
  templateUrl: './submit-quote-model.component.html',
  styleUrls: ['./submit-quote-model.component.scss']
})
export class SubmitQuoteModelComponent implements OnInit, AfterViewChecked {
  SUBMIT_SPINNER = 'submitSpinner';
  submitQuoteForm: FormGroup;
  quoteName: string;
  quoteNote: string;
  availableAccounts: CpqAccount[] = [];
  selectedAccount: CpqAccount;
  needsSfdcOpp: boolean;
  commonSubscription = new Subscription();
  notGuest: boolean;

  constructor(
    private dialogRef: MatDialogRef<SubmitQuoteModelComponent>,
    private spinner: NgxSpinnerService,
    private cartService: CartService,
    private salesforce: SalesforceProxyService,
    private toastr: ToastrService,
    private guestService: GuestConfigService,
    private changeDetector: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit() {
    this.submitQuoteForm = new FormGroup({
      accountSelection: new FormControl('', [Validators.required]),
      quoteName: new FormControl('', [Validators.required, Validators.maxLength(120)]),
      quoteNote: new FormControl('', [Validators.maxLength(2000)]),
    });

    this.notGuest = !this.guestService.getGuestUserDetails()?.isGuest;

    const parallelProcess = [];

    if (this.notGuest) {
      parallelProcess.push(this.cartService.getCpqAccounts().pipe(map(accounts => this.availableAccounts = accounts)));
    }

    parallelProcess.push(this.cartService.getExternalAccountFromOpp(`${this.data.opportunityId}`).pipe(map(opps => {
      if (opps) {
        const opp = opps[0];
        this.needsSfdcOpp = !opp.ExternalId;
        this.selectedAccount = opp.Account;
      }
    })));

    forkJoin(parallelProcess).subscribe(() => {
      if (!this.selectedAccount.AccountNumber && this.availableAccounts.length > 0) {
        this.selectedAccount = this.availableAccounts[0];
      }

      if (this.notGuest && !this.selectedAccount && this.availableAccounts.length === 1) {
        console.log(`Single account logic triggered`);
        this.selectedAccount = this.availableAccounts[0];
        this.onAssociatedAccountChange();
      }
    }, err => {
      // FIXME Add error handling in submit quote init
      console.error(`Init of submit modal failed`, err);
    });
  }

  ngAfterViewChecked() {
    this.changeDetector.detectChanges();
  }

  private conditionallyCreateSfdcOpp(): Observable<any> {
    // FIXME: Make sure that the init has resolved this value
    if (this.needsSfdcOpp) {
      return this.salesforce.createOpportunity(this.data.quoteId, this.data.quoteName, this.selectedAccount?.AccountNumber).pipe(
        switchMap(result => {
          this.selectedAccount.ExternalId = result.externalId;
          return this.cartService.updateObjectId(this.data.opportunityId, {
            ExternalId: result.id,
            Name: this.data.quoteName,
          });
        }),
      );
    }

    return of(false);
  }

  compareObjects(obj1: CpqAccount, obj2: CpqAccount): boolean {
    return obj1?.Id === obj2?.Id;
  }

  submitQuote() {
    if (this.submitQuoteForm.valid) {
      this.spinner.show(this.SUBMIT_SPINNER);

      const workflowTask = this.cartService.startWorkflow(this.data.quoteId);
      const exportTask = this.cartService.triggerExport(this.data.quoteId);

      forkJoin([
        // Update the Quote
        this.cartService.updateObjectId(this.data.quoteId, {
          Name: this.data.quoteName,
          Note: this.data.quoteNote,
        }),
        // Proactively update the products
        this.cartService.updateProducts([this.data.quoteId]),
        // If the FPX Opp doesn't have an ExternalId, create the SFDC Opp and update FPX Opp
        this.conditionallyCreateSfdcOpp(),
      ]).pipe(
        // Pause to give the FPX database a chance to catch-up
        delay(FPX_CREATION_DELAY),
        switchMap(x => iif(() => this.notGuest, workflowTask, of(false))),
        switchMap(x => exportTask),
      ).subscribe(() => {
        // If successful, we need the landing page to know so that it can reload the opp and quote
        this.close(true);

      }, err => {
        this.spinner.hide(this.SUBMIT_SPINNER);
        this.toastr.error(`We're Sorry; something went wrong while submitting your quote.`, `Error`, { timeOut: 5000 });
        // TODO: Add a call to external logging service
      });

    } else {
      this.spinner.hide(this.SUBMIT_SPINNER);
      this.toastr.warning('Please check what you entered and correct any mistakes');
    }
  }

  close(success = false) {
    this.spinner.hide(this.SUBMIT_SPINNER);
    this.dialogRef.close({ success });
  }

  validateMaxLength(fieldName: string): boolean {
    const control = this.submitQuoteForm.controls[fieldName];
    return control?.errors?.maxlength || false;
  }

  validateRequiredField(fieldName: string): boolean {
    const control = this.submitQuoteForm.controls[fieldName];
    return control?.errors?.required || false;
  }

  onAssociatedAccountChange() {
    const updateSubscription = this.cartService.updateObjectId(this.data.opportunityId, {
      AccountId: this.selectedAccount.Id,
    }).subscribe((res) => {
      // Do we need to take any action?
    });

    this.commonSubscription.add(updateSubscription);
  }

}
